<template>
    <div>
       <div>
            <el-breadcrumb separator="/" style="margin-bottom: 20px">
                <el-breadcrumb-item :to="{ path: '/main/dashboard' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>线上咨询</el-breadcrumb-item>
                <el-breadcrumb-item>沟通记录</el-breadcrumb-item>
            </el-breadcrumb>

        </div>
        <el-form :model="form" :rules="rules" ref="form" label-width="150px" class="form">
            <el-row>
                <el-col :span="8">
                    <el-form-item label="院区" prop="hospital_id">
                        {{form.hospital.name}}
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="科室" prop="department_id">
                        {{form.department.name}}
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="医生" prop="doctor_id">
                        {{form.doctor.name}}
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="患者姓名" prop="patient">
                        {{form.patient}}
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="身份证号" prop="idnum">
                        {{form.idnum}}
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="联系电话" prop="phone">
                        {{form.phone}}
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="申请时间" prop="time">
                        {{form.createAt}}
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="更新时间" prop="time">
                        {{form.updatedAt}}
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                </el-col>
            </el-row>
            <el-form-item label="既往病史简述" prop="desc">
                <el-input type="textarea" v-model="form.desc"></el-input>
            </el-form-item>
            <el-form-item label="沟通记录" prop="plan">
                <el-input type="textarea" v-model="form.plan" :rows="10"></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="plan">
                <el-select v-model="form.state" placeholder="选择状态" style="width: 100px" size="small">
                    <el-option label="待沟通" :value="0"></el-option>
                    <el-option label="进行中" :value="1"></el-option>
                    <el-option label="已完成" :value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('form')">保存</el-button>
                <el-button @click="resetForm('form')">重置</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    hospital: {},
                    department: {},
                    doctor: {},
                    state: 1,
                },
                rules: {
                    desc: [
                        {required: true, message: '请输入分时设置', trigger: 'blur'},
                    ],
                }
            };
        },
        computed: {
            title(){
                let s = this.$route.query.s
                if (s) {
                    return s
                }
                return '开始预约'
            }
        },
        methods: {
            async submitForm() {
                console.log(this.form)
                const valid = await this.$refs.form.validate().then(res => res).catch(error => error);
                if (!valid) return
                let method = this.form.id ? 'put' : 'post'
                let url = this.form.id ? `/mingde/consult/${this.form.id}` : `/mingde/consult/`
                const resp = await this.$http[method](url, this.form)
                if (resp.data.code == 200) {
                    this.$message.success("保存成功！")
                    await this.$router.replace("/main/consult/index")
                } else {
                    this.$message.error(resp.data.msg)
                }
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            async getData() {
                let id = this.$route.query.id
                if (id) {
                    const resp = await this.$http.get(`/mingde/consult/${id}`)
                    this.form = resp.data.data
                    if (!this.form.state) this.form.state = 2
                }
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .form {
        margin-top: 20px;
    }
</style>
